import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./mobileHome.scss";
import './MobileMenu.scss';
export default function WorkOffcanvasMenu({ isOpen, onClose }) {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
    // onClose();
  };

  return (
    <div className="position-relative">
      <Button
        variant="primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasTop"
        aria-controls="offcanvasTop"
        className="position-absolute top-0 end-0"
        style={{
          zIndex: 1,
          background: "none",
          border: "none",
          outline: "none",
        }}
      >
        <img
          className="essetionalmenu-icon custom-work"
          src="./open-menu.png"
          alt="Open Menu"
          style={{ width: "33px", height: "33px" }}
        />
      </Button>

      <div
        className={`offcanvas offcanvas-top ${isOpen ? "show" : ""}`}
        tabIndex="-1"
        id="offcanvasTop"
        aria-labelledby="offcanvasTopLabel"
        style={{ backgroundColor: "#000000", height: "auto" }}
      >
        <div className="offcanvas-header">
          <img className="left-top-icon" alt="" src="/layer-1.svg" />
          <button
  type="button"
  className="btn-close text-reset custom-close-button"
  data-bs-dismiss="offcanvas"
  aria-label="Close"
>
  <img
    className="close-menu"
    src="./close-menu.png"
    alt="Close Menu"
    style={{ width: "", height: "30px" }}
  />
</button>

        </div>
        <div className="offcanvas-body small">
          <div className="mobile-menu">
            <div className="menu-items">
              <div
                className="menu-item"
                onClick={() => handleNavigation("/")}
              >
                Home
              </div>
              <div style={{ position: "relative", display: "inline-block" }}>
                <div
                  className="menu-item"
                  style={{
                    color: "#ffffff",
                    position: "relative",
                    zIndex: "1",
                  }}
                  onClick={() => handleNavigation("/work")}
                >
                  Work
                </div>
                <div
                  className="home-icon"
                  style={{
                    height: "21px",
                    width: "21px",
                    position: "absolute",
                    top: "8px",
                    left: "-7px",
                    borderRadius: "50%",
                    backgroundColor: "#5A61FF",
                    zIndex: "0",
                  }}
                ></div>
              </div>
              <div
                className="menu-item"
                onClick={() => handleNavigation("/about")}
              >
                About Us
              </div>
              <div
                className="menu-item"
                onClick={() => handleNavigation("/contact")}
              >
                Contact Us
              </div>
            </div>
            <hr className="menu-divider" />
            <div
              style={{
                marginBottom: "5px",
                fontSize: "21px",
                color: "#9A9A9A",
                textAlign: "center",
                marginLeft: "9px",
                fontWeight: "500",
              }}
            >
              Follow us
            </div>
          </div>
          <div className="social-icons-container" style={{ display: "flex", justifyContent: "center" }}>
  <a href="https://dribbble.com/marasihstudio">
    <img src="/social-icons.svg" alt="Social Media Icon 1" style={{ width: "23px", height: "23px", margin: "0 12px" }} />
  </a>
  <a href="https://www.linkedin.com/company/marasihstudio/">
    <img src="/social-icons-1.svg" alt="Social Media Icon 2" style={{ width: "23px", height: "23px", margin: "0 12px" }} />
  </a>
  <a href="https://www.instagram.com/marasihstudio?igsh=c3Q2aTF0OTF6OWd4&utm_source=qr">
    <img src="/component-2.svg" alt="Social Media Icon 3" style={{ width: "23px", height: "23px", margin: "0 12px" }} />
  </a>
  <a href="URL_OF_SOCIAL_MEDIA_4">
    <img src="/social-icons-2.svg" alt="Social Media Icon 4" style={{ width: "23px", height: "23px", margin: "0 12px" }} />
  </a>
  <a href="URL_OF_SOCIAL_MEDIA_5">
    <img src="/social-icons-3.svg" alt="Social Media Icon 5" style={{ width: "23px", height: "23px", margin: "0 12px" }} />
  </a>
  <a href="URL_OF_SOCIAL_MEDIA_6">
    <img src="/social-icons-4.svg" alt="Social Media Icon 6" style={{ width: "23px", height: "23px", margin: "0 12px" }} />
  </a>
  <a href="https://www.behance.net/marasihstudio">
    <img src="/frame-48.svg" alt="Social Media Icon 7" style={{ width: "23px", height: "23px", margin: "0 12px" }} />
  </a>
</div>
        </div>
      </div>
    </div>
  );
}
