import React from 'react';
import { useNavigate } from 'react-router-dom';
import './contact1.scss';

export default function FooterMobile() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 370); // Scroll to the top of the page
  };
  const handleNavigation1 = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div className='mobile-contact-page'>
      <section className="group-section">
        <div className="ellipse-parent3">
          <div class="e735_647"></div>
          <div className="frame-child44"></div>
          <div className="frame-child45"></div>
        </div>

        <div className="simple-creative">
          <h1 className="be-simple-be-container1">
            <p className="be-simple1">BE SIMPLE.</p>
            <p className="be-creative1">BE CREATIVE.</p>
          </h1>
        </div>

        <div className="frame-wrapper12">
          <div className="work-about-us-parent">
            <div className="work-about-us">
              <div className="got-an-idea1">Got an idea?</div>
            </div>
            <div className="rectangle-parent28">
              <div className="frame-child47"></div>
              <button className="frame-parent34" onClick={() => handleNavigation('/contact')}>
                <div className="lets-get-in-touch-container">
                  <div className="lets-get-in1">
                    Let’s get in touch
                  </div>
                </div>
                <img
                  className="emoji-dizzy-icon1"
                  alt=""
                  src="/-emoji-dizzy.svg"
                />
              </button>
            </div>
          </div>
        </div>

        <div className="line-wrapper">
          <div className="frame-child48"></div>
        </div>

        <div className="design-studio1">
          <div className="blogs-and-contact-us">
            <img
              className="layer-1-icon3"
              loading="lazy"
              alt=""
              src="/layer-1-1.svg"
            />

            <div className="design-studio-container">
              <div className="design-studio2">Design Studio</div>
            </div>
          </div>
        </div>

        <div className="label-stack">
          <div className="icon-grid">
            <div className="explore1">Explore</div>
            <div className="list-item-batch-parent">
              <div className="list-item-batch">
                <div className="work-group">
                  <div className="work1" onClick={() => handleNavigation1('/')}>Home</div>
                  <div className="about-us1" onClick={() => handleNavigation1('/work')}>Work</div>
                  <div className="blogs1" onClick={() => handleNavigation1('/about')}>About</div>
                  <div className="contact-us2" onClick={() => handleNavigation1('/contact')}>Contact</div>
                </div>
              </div>
            </div>
            <div className="follow-us2">Follow us</div>
          </div>
        </div>

        <div className="frame-wrapper13">
  <div className="social-icons-group">
    <a href="https://dribbble.com/marasihstudio">
      <img
        className="social-icons5"
        loading="lazy"
        alt=""
        src="/social-icons.svg"
      />
    </a>

    <a href="https://www.linkedin.com/company/marasihstudio/">
      <img
        className="social-icons6"
        loading="lazy"
        alt=""
        src="/social-icons-1.svg"
      />
    </a>

    <a href="https://www.instagram.com/marasihstudio?igsh=c3Q2aTF0OTF6OWd4&utm_source=qr">
      <img
        className="component-2-icon1"
        loading="lazy"
        alt=""
        src="/component-2.svg"
      />
    </a>

    <a href="URL_OF_SOCIAL_MEDIA_4">
      <img
        className="social-icons7"
        loading="lazy"
        alt=""
        src="/social-icons-2.svg"
      />
    </a>

    <a href="URL_OF_SOCIAL_MEDIA_5">
      <img
        className="social-icons8"
        loading="lazy"
        alt=""
        src="/social-icons-3.svg"
      />
    </a>

    <a href="URL_OF_SOCIAL_MEDIA_6">
      <img
        className="social-icons9"
        loading="lazy"
        alt=""
        src="/social-icons-4.svg"
      />
    </a>

    <a href="https://www.behance.net/marasihstudio">
      <img
        className="frame-child49"
        loading="lazy"
        alt=""
        src="/frame-48.svg"
      />
    </a>
  </div>
</div>

        <div className="marasih-studio-all-rights-res-container">
          <div className="marasih-studio-all1">
            2024 @ marasih studio. All rights reserved
          </div>
        </div>
      </section>
    </div>
  );
}
